<template>
  <b-modal id="Modal_add_role" hide-footer title="Add Role">
    <div class="row">
      <div class="col-12">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="role Name"
            v-model="role_name"
          />
        </div>
        <button class="btn bt-main w-100" @click="confirm()">ADD</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modal_add_role",
  data() {
    return {
      role_name: "",
    };
  },
  methods: {
    validate() {
      if (this.role_name == "") {
        return true;
      } else {
        return false;
      }
    },
    async confirm() {
      try {
        if (this.validate()) {
          throw `กรุณาระบุชื่อบทบาท / Please Enter role Name`;
        }
        let data = { role_name: this.role_name };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `adminit/addrole`,
          data,
          1
        );

        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        this.redata();
        this.hide();
        this.$emit("getrole");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.role_name = "";
    },
    hide() {
      this.$bvModal.hide("Modal_add_role");
    },
  },
};
</script>

<style>
</style>